import React from 'react'
import Layout from '../layout/basic.js'

import '../css/contact_us/style.scss'

import Config from '../config'

const ContactUsPage = () => (
  <Layout currentName="contact_us">
    <section className="contact_us">

      <article className="hero header-hero has-text-centered">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">お問い合わせ</h1>
            <h2 className="subtitle">CONTACT&nbsp;US</h2>
          </div>
        </div>
      </article>

      <article className="main-contents has-text-centered">
        <div className="container columns is-multiline">
          <div className="column is-12">
            <div className="columns">
              <div className="column is-4 is-offset-4">
                <p>当社へのお問い合わせは、下記のアドレスまでメールにてご連絡をお願いします。</p>
                <p className="note">&#64;以降の違いにご注意下さい。</p>
              </div>
            </div>
          </div>
          <div className="column is-12">
            <div className="columns">
              <div className="column is-5 is-offset-1">
                <div className="card">
                  <div className="card-content">
                    <div className="header">
                      <p>
                        <span className="laud-text">会社や事業全体へのお問い合わせ</span>
                      </p>
                    </div>
                    <div className="content">
                      <a href={`mailto:${Config.mail.company}`}>{Config.mail.company}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-5">
                <div className="card">
                  <div className="card-content">
                    <div className="header">
                      <p>
                        <span className="laud-text">システム・エンジニアリング事業に関するお問い合わせ</span>
                      </p>
                    </div>
                    <div className="content">
                      <a href={`mailto:${Config.mail.sesDivision}`}>{Config.mail.sesDivision}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

    </section>
  </Layout>
);
export default ContactUsPage;
